<template>
  <div>
    <v-form ref="form">
      <div class="mt-10">
        Selecione os serviços que deseja executar no instrumento.
        <tabela-padrao-prime-vue
          :key="keyAtualiza"
          v-model="tabelaManutencao.selecionados"
          :dados="tabelaManutencao.dados"
          filtro-geral
          class="mt-2"
          :colunas="tabelaManutencao.colunas"
          paginacao-em-memoria
          sem-paginacao
          :filters="filters"
          ajustar-altura-linha
          :mostrar-acoes="false"
          :por-pagina="tabelaManutencao.porPagina"
          :pagina-atual="tabelaManutencao.paginaAtual"
          :global-filters="tabelaManutencao.colunas.map(c => c.value)"
        >
          <template v-slot:tempoExecucaoCustom="{ slotProps }">
            <input-text
              v-model="slotProps.data.tempoExecucaoCustom"
              sem-label
              flat
              solo
              class="flex-fill input-tabela-analise-tecnica my-1"
              type="number"
              @input="atualizaHorasExecucao"
            />
          </template>
          <template v-slot:faixa="{ slotProps }">
            <div
              v-if="slotProps.data.amplitudeMinima || slotProps.data?.amplitudeMaxima"
              class="alinhar-direita"
            >
            {{
              faixaFormatada(`(${ slotProps.data?.amplitudeMinima + ' a '}
            ${ slotProps.data?.amplitudeMaxima })
            ${slotProps.data?.unidadeMedida?.simbolo || '' }`)
            }}
            </div>
          </template>
        </tabela-padrao-prime-vue>
      </div>
      <div
        class="d-flex mt-4"
        style="justify-content: space-between"
      >
        <botao-padrao
          color="secondary"
          outlined
          @click="voltaStep()"
        >
          <v-icon>$mdiArrowLeft</v-icon>
          {{ $t('modulos.contrato.formulario.itensContrato.steps.voltar') }}
        </botao-padrao>

        <botao-padrao
          color="primary"
          @click="proximoStep()"
        >
          {{ $t('modulos.contrato.formulario.itensContrato.steps.proximo') }}
          <v-icon>$arrowRight</v-icon>
        </botao-padrao>
      </div>
    </v-form>
  </div>
</template>
<script>
import ServicoService from '@common/services/cadastros/ServicoService';
import { FilterMatchMode } from 'primevue/api';
import helpers from '@common/utils/helpers';
// import _ from 'lodash';
export default {
  components: {},
  props: {
    form: { type: Object, default: () => ({}) },
    servicosIds: { type: Array, default: () => [] },
    dadosManutencaoSelecionados: { type: Array, default: () => [] },
    prazoGarantia: { type: Number, default: null },
    participanteId: { type: String, default: null },
    instrumentoId: { type: String, default: null },
    codigoInstrumento: { type: String, default: null },
    codigoOrdemServico: { type: String, default: null },
    nomeTipoInstrumento: { type: String, default: null },
  },
  data() {
    return {
      validaHorasExecucao: true,
      editingRows: [],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      formInstrumento: null,
      precoServicoContrato: null,
      codigoString: '',
      keyAtualiza: 0,
      tabelaManutencao: {
        selecionados: [],
        dados: [],
        colunas: [{
          value: 'codigo',
          text: this.$t('modulos.operacao.tabela.codigo_servico'),
        },
        {
          value: 'nome',
          text: this.$t('modulos.operacao.tabela.nome'),
        },
        {
          value: 'faixa',
          text: this.$t('modulos.operacao.tabela.faixa'),
        },
        {
          value: 'horasExecucao',
          text: this.$t('modulos.operacao.tabela.horas_execucao_padrao'),
          formatter: v => v && helpers.formatarNumeroComPrecisao(v)
        },
        {
          value: 'tempoExecucaoCustom',
          text: this.$t('modulos.operacao.tabela.horas_execucao'),
        },
        {
          value: 'ultimaExecucao',
          text: this.$t('modulos.operacao.tabela.ultima_execucao'),
          formatter: v => v ? helpers.formatarDataBr(v) : '',
        },
      ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    async 'tabelaManutencao.selecionados'() {
      await this.atualizaDadosFormularioManutencao();
      this.$emit('calcula-valor-total');
    },
    async servicosIds() {
      if (this.servicosIds.length > 0) {
        this.selecionaServicosManutencaoChecklist(this.servicosIds);
      }
    },
    instrumentoId() {
      this.buscaDadosListagemManutencao();
    },
    'form.localExecucaoId'() {
      this.buscaDadosListagemManutencao();
    },
    dadosManutencaoSelecionados() {
      this.selecionaManutencoesDaEdicao();
    },
  },
  methods: {
    buscaDadosListagemManutencao() {
      if (this.form.localExecucaoId && this.instrumentoId) {
        this.listarServicosManutencao();
      }
    },
    listarServicosManutencao() {
      let params = {
        localExecucaoId: this.form.localExecucaoId,
        instrumentoId: this.instrumentoId,
        tipoServico: 2,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      ServicoService.listaServicosPeloPontoFaixaInstrumento(params)
        .then((res) => {
          this.tabelaManutencao.dados = res.data;
          this.tabelaManutencao.dados.map((item) => {
            item.horasExecucao = item.tempoExecucao;
            item.tempoExecucaoCustom = item.tempoExecucao;
          });

          if (this.servicosIds.length > 0) {
            this.selecionaServicosManutencaoChecklist(this.servicosIds);
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          if (this.dadosManutencaoSelecionados?.length)
            this.selecionaManutencoesDaEdicao();
        });
    },
    selecionaManutencoesDaEdicao() {
      this.tabelaManutencao.selecionados = [];
      this.tabelaManutencao.dados.forEach((item) => {
        this.dadosManutencaoSelecionados.forEach((itemEdicao) => {
          if (item.id == itemEdicao.servico.id) {
            this.tabelaManutencao.selecionados.push(item);
          }
        });
      });

      this.tabelaManutencao.selecionados.map((item) => {
        this.dadosManutencaoSelecionados.forEach((itemEdicao) => {
          if (item.id == itemEdicao.servico.id) {
            item.tempoExecucaoCustom = itemEdicao.servico.tempoExecucaoCustom;
          }
        });
      });
      ++this.keyAtualiza;
      this.$emit('calcula-valor-total');
    },
    selecionaServicosManutencaoChecklist(servicosIds) {
      this.tabelaManutencao.selecionados = [];
      this.tabelaManutencao.dados.forEach((itemManutencao) => {
        servicosIds.forEach((servicoId) => {
          if (itemManutencao.id == servicoId) {
            this.tabelaManutencao.selecionados.push(itemManutencao);
          }
        });
      });
    },
    atualizaDadosFormularioManutencao() {
      this.form.manutencoes = [];
      this.form.dadosManutencaoResumo = [];
      if (this.tabelaManutencao.selecionados.length) {
        this.form.dadosManutencaoResumo = this.tabelaManutencao.selecionados;
        this.tabelaManutencao.selecionados.map((itemManutencao) => {
          this.form.manutencoes.push({
            servico: {
              id: itemManutencao.id,
              tempoExecucao: itemManutencao.horasExecucao,
              tempoExecucaoCustom: itemManutencao.tempoExecucaoCustom,
              horasPrevistas: itemManutencao.horasPrevistas,
              valor: itemManutencao.valorServico,
              valorHora: itemManutencao.valorHora,
              minimoPontos: itemManutencao.minimoPontos,
              valorPonto: itemManutencao.valorPonto,
              ultimaExecucao: itemManutencao.ultimaExecucao,
            },
          });
        });
      }
    },
    atualizaHorasExecucao(){
      this.atualizaDadosFormularioManutencao();
      this.$emit('calcula-valor-total');
    },
    voltaStep() {
      this.$emit('volta-step-2', 2);
    },
    proximoStep() {
      const valido = this.tabelaManutencao.selecionados.every(el => !!parseFloat(el.tempoExecucaoCustom))
      if (!valido)
        return this.toastAlerta(this.$t(`modulos.operacao.erros.horas_execuxao_nao_pode_zero`));

      this.$emit('proximo-step-4', 4);
    },
    faixaFormatada(value) {
      return String(value).replaceAll('.', ',');
    },
  },
};
</script>
